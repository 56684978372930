import { graphql, useStaticQuery } from "gatsby";

export const useFooterImages = () => {
  const { hromada, solutionsurfers } = useStaticQuery(graphql`
    query FooterImage {
      hromada: file(relativePath: { eq: "Footer/footer-image.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      solutionsurfers: file(
        relativePath: { eq: "Footer/solution_surfers_logo.png" }
      ) {
        childImageSharp {
          fixed(width: 275, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return {
    hromada: hromada.childImageSharp.fluid,
    solutionsurfers: solutionsurfers.childImageSharp.fixed,
  };
};
