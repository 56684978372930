import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import EmailIcon from "../../../../../../../../assets/icons/email_kontakt_icon.svg";
import PhoneIcon from "../../../../../../../../assets/icons/phone_kontakt_icon.svg";
import { SmallTitle } from "../../SmallTitle";

export interface ContactItemsProps {
  className?: string;
}

const ContactItemsInner = (props: ContactItemsProps) => {
  const { t } = useTranslation("translations");

  return (
    <div className={props.className}>
      <Grid container spacing={2}>
        <Grid item md={5} sm={12}>
          <div className="contact_item">
            <img src={PhoneIcon} alt="" />
            <div>
              <SmallTitle className="bold">
                <a href={`tel: ${t("contact.phone")}`}> {t("contact.phone")}</a>
              </SmallTitle>
              <SmallTitle> {t("contact.available")}</SmallTitle>
            </div>
          </div>
        </Grid>
        <Grid item md={2} container justify="center">
          <Divider className="divider_white" orientation="vertical" flexItem />
        </Grid>
        <Grid item md={5} sm={12}>
          <div className="contact_item">
            <img src={EmailIcon} alt="" />
            <div>
              <SmallTitle className="bold">
                <a href={`mailto: ${t("contact.email")}`}>
                  {" "}
                  {t("contact.email")}
                </a>
              </SmallTitle>
              <SmallTitle>{t("contact.emailText")}</SmallTitle>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export const ContactItems = styled(ContactItemsInner)`
  display: flex;
  align-items: center;
  margin-top: 15px;
  justify-content: space-between;

  .contact_item {
    display: flex;
  }
  .divider_white {
    background-color: white;
    height: 100%;
  }

  ${SmallTitle} {
    margin: 0 12px;
  }

  .icon {
    color: ${props => props.theme.color.white};
    font-size: 40px;
  }

  img {
    transform: scale(1.75);
  }
`;
