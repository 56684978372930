import React from "react";
import styled from "styled-components";
import { theme } from "../../../../Theme/Theme";

export interface LanguageLinkProps {
  current?: boolean;
  label: string;
  link: string;
  className?: string;
}

const LanguageLinkInner = (props: LanguageLinkProps) => {
  const { label, link } = props;
  return (
    <a href={link} className={props.className}>
      {label}
    </a>
  );
};

export const LanguageLink = styled(LanguageLinkInner)`
  ${props =>
    props.current &&
    `
  color: ${props.theme.color.primary} !important;
`}

  color: ${theme.color.primaryDark};
  font-weight: 600;
  font-size: 2rem;
`;
