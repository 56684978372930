export interface Route {
  label: string;
  to: string;
  hideInMenu?: boolean;
}

export const routes = {
  home: {
    hideInMenu: true,
    label: "O mne",
    to: "/",
  } as Route,
  whyWithMe: {
    hideInMenu: false,
    label: "Prečo so mnou?",
    to: "/preco-so-mnou/",
  } as Route,
  services: {
    label: "Služby",
    to: "/sluzby/",
  } as Route,
  freeResources: {
    label: "Obsah zadarmo",
    to: "/obsah-zadarmo/",
  } as Route,
  blog: {
    label: "Blog",
    to: "/blog/",
  } as Route,
  allBlogPosts: {
    label: "allBlogPosts",
    to: "/blog/all",
    hideInMenu: true,
  } as Route,
  contact: {
    label: "Kontakt",
    to: "/kontakt/",
  } as Route,
  ebook: {
    label: "Ebook",
    to: "/ebook/",
    hideInMenu: true,
  } as Route,
  servicesFolder: {
    coaching: {
      label: "Coaching",
      to: "/sluzby/brief-koucing/",
    },
    therapy: {
      label: "Therapy",
      to: "/sluzby/brief-terapia/",
    },
    workshops: {
      label: "Workshops",
      to: "/sluzby/inspirativne-workshopy/",
    },
    leadership: {
      label: "Therapy",
      to: "/sluzby/liderske-a-obchodne-zrucnosti/",
    },
  },
};
