import { createMuiTheme } from "@material-ui/core";
import { darken, lighten, rgba, shade } from "polished";

const primaryColor = "#ECA93F";

export const theme = {
  name: "Default",
  fonts: {
    secondary: "Grand Hotel",
  },
  color: {
    background: "#ffffff",
    white: "#ffffff",
    primary: primaryColor,
    primaryDark: "#0D2C69",
    gray: "#727271 ",
    lightGray: "#E5E5E5",
    sectionBg: "#F5F5F5",
    yellow: "#ECA93F",
  },
  borderRadius: "16px",
  spacing: {
    sectionMarginTop: "14rem",
  },
};

export const muiTheme = createMuiTheme();
