import Grid from "@material-ui/core/Grid";
import GatsbyImage from "gatsby-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import { muiTheme } from "../../../../../../Theme/Theme";
import { useFooterImages } from "../../../hooks/useFooterImages";
import { SmallTitle } from "../../SmallTitle";
import { FooterNavigation } from "../FooterNavigation/FooterNavigation";

export interface TopRightGridProps {
  className?: string;
}

const ImageWrapper = styled.div`
  position: absolute;
	border-radius: 50%;
	overflow: hidden;

  /* right: 20px; */
  transform: translateY(-50%) translateX(50%);

  max-width: 300px;
  display: flex;
  justify-content: center;
  width: 100%;

  ${SmallTitle} {
    text-align: center;
  }

  ${muiTheme.breakpoints.down("md")} {
    right: 0;
    left: 0;
    margin: auto;
    transform: translateY(-50%);
  }
`;

const FooterRightBottomSection = styled.div`
  margin-top: 120px;
  text-align: center;

  ${muiTheme.breakpoints.down("md")} {
    margin-top: 160px;
  }
`;

const TopRightGridInner = (props: TopRightGridProps) => {
  const footerImages = useFooterImages();
  const { t } = useTranslation("translations");

  return (
    <Grid
      className={props.className}
      container
      item
      md={6}
      sm={12}
      justify="center"
      direction="column"
    >
      <ImageWrapper>
        <GatsbyImage
          style={{
            width: "100%",
            height: "100%",
          }}
          fluid={footerImages.hromada}
        />
      </ImageWrapper>
      <FooterRightBottomSection>
        <SmallTitle>{t("footer.position")}</SmallTitle>
        <FooterNavigation />
        {/* <GeneralLinks /> */}
      </FooterRightBottomSection>
    </Grid>
  );
};

export const TopRightGrid = styled(TopRightGridInner)`
  position: relative;

  ${muiTheme.breakpoints.down("md")} {
    order: 1;
  }
`;
